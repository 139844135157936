@import "./assets/common-styles/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text&family=Noto+Sans:wght@300;400&family=Open+Sans:wght@300;400;500;600&display=swap');
@import 'primeicons/primeicons.css';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');


app-root {   z-index: 1; }  
app-toast, .toast__container {   z-index: 2; }
*{
     
     font-family: $Notosans;
     font-weight: 400;
     font-style: normal;
     font-display: swap;
}


