$black: #1b295c;
$textBlue: #1b295c;
$orange: #de7141;
$white: #ffffff;
$grey: #5f6d7e;
$borderGray2: #c9ced3;
$greydisable: #98a0a6;
$blue: #3580d8;
$seaGreen: #1cb096;
$layoffBackground: rgb(0, 0, 0, 0.4000000059604645);
$orangegradient: linear-gradient(180deg, #de7141 0%, #ffbc9e 100%);
$lightgradient: linear-gradient(
  344.69deg,
  rgba(27, 41, 92, 0) 16.16%,
  rgba(27, 41, 92, 0.21) 83.19%
);
$Notosans: "Noto sans";
$boxshawdow: rgba(0, 0, 0, 0.15);
$borderash: rgba(121, 124, 127, 0.2);
$otherstroke: #c9ced3;
$green: #17a425;
$bordergrey: rgba(201, 206, 211, 0.6);
$boxshawdow3: 0 2px 0 0 rgba(121, 124, 127, 0.2);
$boxshawdow1: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
$boxshawdow2: 0px -4px 4px 0px rgba(41, 54, 101, 0.1);
$backgroundcolor: #f4fbff;
$borderash1: rgba(152, 160, 166, 1);
$borderash2: rgba(95, 109, 126, 1);
$backgroundcolor1: rgba(201, 206, 211, 0.2);
$placeholder: rgba(95, 109, 126, 0.7);
$boxshawdow4: rgba(0, 0, 0, 0.08);
$blue: #3580d8;
$green1: #1cb096;
$whiteGrayGradient: linear-gradient(
  257.41deg,
  rgba(236, 236, 241, 0.7) -5.74%,
  rgba(236, 236, 241, 0.15) 97.21%
);
$boxshawdow5: 0px -4px 4px 0px #2936651a;
$boxshawdow7: 0 0 6px 0 rgba(0, 0, 0, 0.25);
$pink: #ca2688;
$violet: #890de9;
$boxshadow8: 2px -1px 6px 0 rgba(0, 0, 0, 0.25);
$bordergrey1: rgba(0, 0, 0, 0.2);
$backgroundcolor2: #f7f7f7;
$innershadow: rgba(152, 160, 166, 0.1);
$ash3: #2e3646;
$boxshadow9: rgba(0, 0, 0, 0.08);
$ash5: #f0f0f0;
$lightblue: #dcecff;
$boxshadow10: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
$blue1: #214081;
$orange1: #e96e3b;
$orange2: #e06131;
$lightblue1: #88cee7;
$borderGrey3: rgba(95, 109, 126, 0.3);
